<template>
  <div>
    <strong>Akcje</strong>
    <div
      style="display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;"
    >
      <div
        v-for="(node, nodeIndex) in actionAccessesTree"
        :key="`node-${nodeIndex}`"
        class="actions-node"
      >
        <h5>{{ node.label }}</h5>
        <div style="padding-left: 15px">
          <span v-if="node.actions && node.actions.every(action => action.value === 0 || action.value === false) ">Brak uprawnień</span>
          <span v-if="!node.actions">Brak uprawnień</span>
          <div
            v-for="(action, index) in node.actions"
            :key="index"
          >
            <span>{{ (action.value !== 0 && action.value !== false) ? action.label : '' }}</span>
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    actionAccessesTree: { type: Array }
  }
}
</script>
